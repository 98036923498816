@use '/src/packages/assets/styles/typography';

.column {
  @extend .fontBase;

  width: 100%;
  padding: 8px 16px 16px 16px;
  background: var(--settings-grid-column-bg);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
}

.title {
  @extend .body-m-bold;
}

.settingsOption {
  @extend .body-s;

  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: 16px;
  margin-bottom: 16px;
}

.blockTitle {
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  padding: 4px 0;

  margin-top: 24px;
}

.blockHint {
  font-size: 13px;
  line-height: 16px;
  color: var(--go-input-label-text);
}

.settingsField {
  width: 200px;
}
